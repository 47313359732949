.App {
  text-align: center;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  flex-direction: column;

}

.headerContainer {
 min-height: 300px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 40px;
  flex-shrink: 1;
}

.body {
  width: 98%;
  flex: 1;
  height: 100%;
  height: auto;
  color: black;
  background: white;
  border-radius: 30px 30px 0 0 ;
  scrollbar-width: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.274);
  display: flex;
  padding: 40px;
  padding-top: 60px;
  box-sizing: border-box;
}

.card-container {
  display: grid;
  flex: 1;
  grid-auto-rows: 1fr;
  gap: 40px;
  grid-template-columns: repeat(4,1fr);
  box-sizing: border-box;
  width: 100%;

}

.card {
  width:100%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.233);
  display: flex;
  flex-direction: column;
  cursor: alias;
  height: 100%;
}


@media (min-width: 0em) {
  .card {
    grid-column: span 4;
  }

  .card-container {
    padding: 0 !important;
  }

  .body {
    padding: 30px
  }
}

@media (min-width: 40em) {
  .card {
    grid-column: span 2;
  }
  .card-container {
    padding: 20px;

  }

  .body {
    padding: 40px
  }
}

@media (min-width: 70em) {
  .card {
    grid-column: span 1;
  }

}

.card-content {
  padding: 10px;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;


}

img {
  width: 100%;
  max-height: 200px;
  flex: 1;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

h1 {
  margin: 0;
  font-size: 4em;
  color: white;
}
h2 {
  color: white
}

h3, a {
  margin: 0;
  flex-shrink: 1;
}

a {
  font-style: italic;
  color: white;
  opacity: 0.5;
}
p {
  flex: 1;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}